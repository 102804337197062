import ReactDOM from "react-dom";
import React, {useState} from "react";
import {
    Trash,
    CornerDownRight,
    CornerUpRight,
    CornerLeftDown,
    CornerRightDown,
    X,
    AlignLeft,
    AlignRight,
    AlignCenter,
    ChevronRight,
    Columns,
    Table,
    Image
} from 'react-feather';


const TableIcon = ({ style, ...props }) => (
    <svg
        viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
        style={style}
        {...props}
    >
        <rect x="3" y="3" width="7" height="7"></rect>
        <rect x="14" y="3" width="7" height="7"></rect>
        <rect x="14" y="14" width="7" height="7"></rect>
        <rect x="3" y="14" width="7" height="7"></rect>
    </svg>
);

const TableContextMenu = ({x, y, onClose, onAction}) => {
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const handleMouseEnter = (submenu) => {
        setActiveSubmenu(submenu);
    };

    const handleMouseLeave = () => {
        setActiveSubmenu(null);
    };

    return (
        <div
            className="table-context-menu"
            style={{
                position: 'fixed',
                top: `${y}px`,
                left: `${x}px`,
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                zIndex: 1000,
            }}
            onMouseLeave={onClose}
        >
            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                <li
                    className="context-menu-item"
                    onMouseEnter={() => handleMouseEnter('alignment')}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <TableIcon style={{width: '20px', height: '20px'}}></TableIcon>
                    Position
                    <ChevronRight size={18} className="rightIcons"></ChevronRight>
                    {activeSubmenu === 'alignment' && (
                        <div className="submenu">
                            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignLeft')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 19px 1px 1px"
                                        }}
                                    >
                                        <TableIcon style={{width: '18px', height: '18px'}}/>
                                    </div>
                                    Tableau à gauche
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignCenter')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 10px 1px 10px"
                                        }}
                                    >
                                        <TableIcon style={{width: '18px', height: '18px'}}/>
                                    </div>
                                    Tableau au centre
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignRight')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 1px 1px 19px"
                                        }}
                                    >
                                        <TableIcon style={{width: '18px', height: '18px'}}/>
                                    </div>
                                    Tableau à droite
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignLeftWithText')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 1px"
                                        }}
                                    >
                                        <TableIcon style={{width: '18px', height: '18px'}}/>
                                        <AlignLeft size={15} style={{alignSelf: 'flex-start'}}/>
                                    </div>
                                    Tableau + Paragraphe à droite
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignRightWithText')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 1px"
                                        }}
                                    >
                                        <AlignLeft size={15} style={{alignSelf: 'flex-start'}}/>
                                        <TableIcon style={{width: '18px', height: '18px'}}/>
                                    </div>
                                    Paragraphe à gauche + Tableau
                                </li>
                            </ul>
                        </div>
                    )}
                </li>

                <li
                    className="context-menu-item"
                    onMouseEnter={() => handleMouseEnter('row')}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <Table size={20}></Table>
                    Ligne
                    <ChevronRight size={18} className="rightIcons"></ChevronRight>
                    {activeSubmenu === 'row' && (
                        <div className="submenu">
                            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('insertRowAbove')}
                                >
                                    <CornerUpRight size={18}></CornerUpRight>
                                    Insérer une ligne au-dessus
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('insertRowBelow')}
                                >
                                    <CornerDownRight size={18}></CornerDownRight>
                                    Insérer une ligne ci-dessous
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('deleteRow')}
                                >
                                    <X size={18}></X>
                                    Supprimer la ligne
                                </li>
                            </ul>
                        </div>
                    )}
                </li>

                <li
                    className="context-menu-item"
                    onMouseEnter={() => handleMouseEnter('column')}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <Columns size={20}></Columns>
                    Colonne
                    <ChevronRight size={18} className="rightIcons"></ChevronRight>
                    {activeSubmenu === 'column' && (
                        <div className="submenu">
                            <ul style={{ listStyle: 'none', margin: 0, padding: '4px 0' }}>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('insertColumnLeft')}
                                >
                                    <CornerLeftDown size={18}></CornerLeftDown>
                                    Insérer une colonne à gauche
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('insertColumnRight')}
                                >
                                    <CornerRightDown size={18}></CornerRightDown>
                                    Insérer une colonne à droite
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('deleteColumn')}
                                >
                                    <X size={18}></X>
                                    Supprimer la colonne
                                </li>
                            </ul>
                        </div>
                    )}
                </li>

                <li
                    className="context-menu-item"
                    onClick={() => onAction('removeTable')}
                >
                    <Trash size={20}></Trash>
                    Supprimer le tableau
                </li>
            </ul>
        </div>
    );
};

class CustomTableResize {
    constructor(quill, options = {}) {
        this.quill = quill;
        this.options = options;
        this.resizing = false;
        this.currentCell = null;
        this.currentTable = null
        this.resizeDirection = null;

        this.contextMenu = null;
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.handleTableAction = this.handleTableAction.bind(this);

        // Bind methods
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);

        // Initialize
        this.addEventListeners();
        //this.detectTables(); // Detect tables immediately on initialization
    }

    addEventListeners() {
        this.quill.on('text-change', () => {
            this.detectTables();
        });
    }

    detectTables() {
        const tables = this.quill.root.querySelectorAll('table');
        tables.forEach((table) => {
            if (!table.dataset.resizable) {
                this.setupTable(table);
            } else {
                // Reapply listeners to existing tables
                this.updateTableHandles(table);
                table.addEventListener('contextmenu', this.handleContextMenu);
            }
        });
    }

    setupTable(table) {
        table.dataset.resizable = 'true';

        table.style.position = 'relative';
        table.style.borderCollapse = 'separate';
        table.style.borderSpacing = '0';

        this.updateTableHandles(table);
        table.addEventListener('contextmenu', this.handleContextMenu);
    }

    removeContextMenu() {
        try {
            if (this.contextMenu && document.body.contains(this.contextMenu)) {
                document.body.removeChild(this.contextMenu);
            }
            this.contextMenu = null;
        } catch (error) {
            console.error("Failed to remove context menu:", error);
        }
    }

    handleContextMenu(e) {
        e.preventDefault();

        // Remove existing context menu if any
        this.removeContextMenu();

        // Create context menu container
        const menuContainer = document.createElement('div');
        const targetCell = e.target.closest('td, th');
        const targetTable = e.target.closest('table');

        if (!targetCell || !targetTable) return;

        // Render the React component
        ReactDOM.render(
            <TableContextMenu
                x={e.pageX}
                y={e.pageY}
                onClose={this.removeContextMenu}
                onAction={(action) => this.handleTableAction(action, targetCell, targetTable)}
            />,
            menuContainer
        );

        document.body.appendChild(menuContainer);
        this.contextMenu = menuContainer;

        // Close menu when clicking outside
        const closeMenu = (e) => {
            if (!menuContainer.contains(e.target)) {
                this.removeContextMenu();
                document.removeEventListener('click', closeMenu);
            }
        };

        setTimeout(() => {
            document.addEventListener('click', closeMenu);
        }, 0);
    }

    handleTableAction(action, cell, table) {
        const rowIndex = cell.parentElement.rowIndex;
        const cellIndex = cell.cellIndex;

        switch (action) {
            case 'insertColumnRight':
                this.insertColumn(table, cellIndex + 1);
                break;
            case 'insertColumnLeft':
                this.insertColumn(table, cellIndex);
                break;
            case 'deleteColumn':
                this.deleteColumn(table, cellIndex);
                break;
            case 'insertRowAbove':
                this.insertRow(table, rowIndex);
                break;
            case 'insertRowBelow':
                this.insertRow(table, rowIndex + 1);
                break;
            case 'deleteRow':
                this.deleteRow(table, rowIndex);
                break;
            case 'removeTable':
                this.removeTable(table);
                break;
            case 'alignLeft':
                this.alignTable(table, 'left');
                break;
            case 'alignCenter':
                this.alignTable(table, 'center');
                break;
            case 'alignRight':
                this.alignTable(table, 'right');
                break;
            case "alignLeftWithText":
                this.alignTable(table, 'left-with-text');
                break;
            case "alignRightWithText":
                this.alignTable(table, 'right-with-text');
                break;
        }

        // Close the context menu
        this.removeContextMenu();
    }

    alignTable(table, alignment) {
        // Find the table container
        let tableContainer = table.parentNode.parentNode.parentNode;  //table -> span -> div -> p

        // Remove any existing alignment classes
        table.classList.remove('table-align-left', 'table-align-center', 'table-align-right', 'table-align-right-with-text', 'table-align-left-with-text');


        const newParagraph = document.createElement('p');
        switch (alignment) {
            case 'left':
            case 'center':
            case 'right':
                tableContainer.removeAttribute('contenteditable');
                table.classList.add(`table-align-${alignment}`);
                break;
            case 'left-with-text':
            case 'right-with-text':
                tableContainer.setAttribute('contenteditable', 'false');
                tableContainer.insertAdjacentElement('afterend', newParagraph);
                table.classList.add(`table-align-${alignment}`);
                break;
        }
        // Add new alignment class
    }

    deleteRow(table, rowIndex) {
        // Check if this is not the last row
        if (table.rows.length > 1) {
            table.deleteRow(rowIndex);
        } else {
            // If it's the last row, remove the whole table
            this.removeTable(table);
        }
    }

    deleteColumn(table, columnIndex) {
        const rows = table.rows;
        // Check if this is not the last column
        if (rows[0].cells.length > 1) {
            for (let i = 0; i < rows.length; i++) {
                rows[i].deleteCell(columnIndex);
            }
        } else {
            // If it's the last column, remove the whole table
            this.removeTable(table);
        }
    }

    insertColumn(table, index) {
        const rows = table.rows;
        for (let i = 0; i < rows.length; i++) {
            const cell = rows[i].insertCell(index);
            const cellHeight = rows[i].children[0].clientHeight
            const cellWidth = rows[i].children[0].clientWidth
            cell.style.width = `${cellWidth}px`;
            cell.style.height = `${cellHeight}px`;
            cell.contentEditable = 'false';
            const div = document.createElement('div');
            div.style.height = '100%'; // Make the div take up the full height of the td
            div.contentEditable = 'false'; // Allow cell content editing

            const p = document.createElement('p');
            p.style.height = '100%'; // Make the div take up the full height of the td
            p.contentEditable = 'true'; // Allow typing only inside the <p>
            p.innerHTML = '<br>'; // Ensure an empty but non-deletable paragraph

            div.appendChild(p);
            cell.appendChild(div);

            this.addResizeHandles(table, cell);
        }
    }

    insertRow(table, index) {
        const row = table.insertRow(index);
        const columnCount = table.rows[0].cells.length;
        for (let i = 0; i < columnCount; i++) {
            const cell = row.insertCell(i);
            cell.contentEditable = 'false';

            const div = document.createElement('div');
            div.style.height = '100%'; // Make the div take up the full height of the td
            div.contentEditable = 'false'; // Allow cell content editing

            const p = document.createElement('p');
            p.style.height = '100%'; // Make the div take up the full height of the td
            p.contentEditable = 'true'; // Allow typing only inside the <p>
            p.innerHTML = '<br>'; // Ensure an empty but non-deletable paragraph

            div.appendChild(p);
            cell.appendChild(div);

            this.addResizeHandles(table, cell);
        }
    }

    removeTable(table) {
        // Find the Quill container element
        const quillContainer = this.quill.root;

        // Find the table's parent paragraph or div
        let tableContainer = table.parentNode.parentNode

        // Remove the table or its container
        tableContainer.remove();

        // Ensure the editor updates
        this.quill.update();
    }

    updateTableHandles(table) {
        const cells = table.querySelectorAll('td, th');
        cells.forEach((cell) => {
            // Remove existing handles (if any) to avoid duplication
            const existingHandles = cell.querySelectorAll('.table-resize-handle');
            existingHandles.forEach((handle) => handle.remove());

            // Add new resize handles
            this.addResizeHandles(table, cell);
        });
    }

    addResizeHandles(table, cell) {
        cell.style.position = 'relative';

        // Right handle
        const rightHandle = document.createElement('div');
        rightHandle.className = 'table-resize-handle vertical right';
        rightHandle.style.cssText = `
            position: absolute;
            top: 0;
            right: -3px;
            width: 8px;
            height: 100%;
            cursor: col-resize;
        `;
        rightHandle.setAttribute('contenteditable', 'false');

        // Left handle (new)
        const leftHandle = document.createElement('div');
        leftHandle.className = 'table-resize-handle vertical left';
        leftHandle.style.cssText = `
            position: absolute;
            top: 0;
            left: -3px;
            width: 8px;
            height: 100%;
            cursor: col-resize;
        `;
        leftHandle.setAttribute('contenteditable', 'false');

        // Bottom handle
        const bottomHandle = document.createElement('div');
        bottomHandle.className = 'table-resize-handle horizontal';
        bottomHandle.style.cssText = `
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 100%;
            height: 8px;
            cursor: row-resize;
        `;
        bottomHandle.setAttribute('contenteditable', 'false');

        // Add event listeners
        rightHandle.addEventListener('mousedown', (e) => this.handleMouseDown(e, table, cell, 'vertical-right'));
        leftHandle.addEventListener('mousedown', (e) => this.handleMouseDown(e, table, cell, 'vertical-left'));
        bottomHandle.addEventListener('mousedown', (e) => this.handleMouseDown(e, table, cell, 'horizontal'));

        // Append all handles
        cell.appendChild(rightHandle);
        cell.appendChild(leftHandle);
        cell.appendChild(bottomHandle);
    }

    handleMouseDown(e, table, cell, direction) {
        e.preventDefault();

        this.resizing = true;
        this.currentCell = cell;
        this.currentTable = table
        this.resizeDirection = direction;

        this.startX = e.pageX;
        this.startY = e.pageY;
        this.startWidth = cell.offsetWidth;
        this.startHeight = cell.offsetHeight;

        // For left handle resizing, we need the previous cell's width
        if (direction === 'vertical-left' && cell.previousElementSibling) {
            this.previousCellStartWidth = cell.previousElementSibling.offsetWidth;
        }

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseMove(e) {
        if (!this.resizing) return;

        const cell = this.currentCell;
        const table = this.currentTable

        const rowIndex = cell.parentElement.rowIndex;
        const cellIndex = cell.cellIndex;
        const rows = table.rows;

        if (this.resizeDirection === 'vertical-right') {
            const deltaX = e.pageX - this.startX;
            const newWidth = this.startWidth + deltaX; // Minimum width of 20px

            for (let i = 0; i < rows.length; i++) {
                rows[i].cells[cellIndex].style.width = `${newWidth}px`;
            }
        } else if (this.resizeDirection === 'vertical-left') {
            const deltaX = e.pageX - this.startX;

            // Resize current cell
            const newWidth = this.startWidth - deltaX;
            cell.style.width = `${newWidth}px`;
            for (let i = 0; i < rows.length; i++) {
                rows[i].cells[cellIndex].style.width = `${newWidth}px`;
            }

            // Resize previous cell if it exists
            if (cell.previousElementSibling) {
                const previousCellIndex = cell.previousElementSibling.cellIndex;
                const newPreviousWidth = this.previousCellStartWidth + deltaX;

                for (let i = 0; i < rows.length; i++) {
                    rows[i].cells[previousCellIndex].style.width = `${newPreviousWidth}px`;
                }
            }
        } else if (this.resizeDirection === 'horizontal') {
            const deltaY = e.pageY - this.startY;
            const newHeight = this.startHeight + deltaY;
            const numberOfCellsInThisLine = table.rows[rowIndex].cells.length
            for (let i = 0; i < numberOfCellsInThisLine; i++) {
                table.rows[rowIndex].cells[i].style.height = `${newHeight}px`;
            }
            //cell.style.height = `${newHeight}px`;
        }
    }

    handleMouseUp() {
        this.resizing = false;
        this.currentCell = null;
        this.currentTable = null
        this.resizeDirection = null;

        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }
}

export default CustomTableResize