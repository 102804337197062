import React, {useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './QuillTextEditor.css'
import ValidationUI from "../ValidationUI";
import axios from 'axios'
import {toastr} from "react-redux-toastr";
import moment from "moment";

import ImageResize from 'quill-image-resize';
import {Trash, CornerDownRight, CornerUpRight, CornerLeftDown, CornerRightDown, XSquare, X, AlignLeft, AlignRight, AlignCenter, ChevronRight, Columns, Table, Image} from 'react-feather';
import ReactDOM from "react-dom";
import ImageContextMenu from "./quillTextEditorUtils/imageContextMenu";
import TableResize from "./quillTextEditorUtils/tableResize";
import TableSelector from "./quillTextEditorUtils/tableSelectorPopUp";
import CustomTableResize from "./quillTextEditorUtils/tableResize";


const Quill = ReactQuill.Quill

const Font = Quill.import('formats/font')
Font.whitelist = ['', 'Arial', 'Georgia', 'Helvetica', 'Tahoma', 'Times', 'Trebuchet', 'Verdana']
Quill.register(Font, true)

const Embed = Quill.import('blots/embed');
class ImageBlot extends Embed {
    static create(value) {
        const node = super.create();

        // Create the image element
        const img = document.createElement('img');

        img.setAttribute('src', value.src || value);  // || value case is for the old images only, in that case tha value is a string containing the src directly
        //img.classList.add('ql-image'); // Helps the resize module recognize the image
        /*img.setAttribute('alt', value.alt || ''); // Support alt text*/

        const styleOfOldImages = 'max-width: 100%; height: auto; display: block;'
        if (value && !value.src) {
            img.setAttribute('style', styleOfOldImages); // Apply any styles that the old images had
        }

        if (value.width) {
            img.setAttribute('width', value.width); // Apply any styles if provided
        }
        if (value.height) {
            img.setAttribute('height', value.height); // Apply any styles if provided
        }
        if (value.classList && Array.isArray(value.classList)) {
            img.classList.add(...value.classList); // Restore the classList
        }

        //container.appendChild(img);
        node.appendChild(img);

        return node;
    }

    static value(node) {
        // Find the img element within the container
        const img = node.querySelector('img');
        if (!img) return null;

        return {
            src: img.getAttribute('src'),
            width: img.getAttribute('width'),
            height: img.getAttribute('height'),
            classList: Array.from(img.classList),
        };
    }

    attach() {
        super.attach();
        const img = this.domNode.querySelector('img');

        // Attach to image element
        if (img && !img._contextMenuAttached) {
            img.addEventListener('contextmenu', this.handleContextMenu.bind(this));
            img._contextMenuAttached = true;
        }

        // Attach global listener (once per instance)
        if (!this._globalListenerAttached) {
            document.addEventListener('contextmenu',
                (e) => {
                if (this.isEventOnImage(e)) {
                    e.preventDefault(); // Block default browser menu
                    this.handleContextMenu(e);
                }
            },
                true
            );
            this._globalListenerAttached = true;
        }
    }
    isEventOnImage(e) {
        const img = this.domNode.querySelector('img');
        const numberOfTargetChildren = e.target.children.length
        const expectedNumberOfTargetChildren = 5
        const targetFirstChildHTML = e.target.children[0]?.outerHTML
        const expectedTargetHTML = `<div style=\"position: absolute; height: 12px; width: 12px; background-color: white; border: 1px solid rgb(119, 119, 119); box-sizing: border-box; opacity: 0.8; cursor: nwse-resize; left: -6px; top: -6px;\"></div>`
        const clickedOnImage = numberOfTargetChildren === expectedNumberOfTargetChildren && targetFirstChildHTML === expectedTargetHTML
        return clickedOnImage
    }

    handleContextMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        // Remove existing context menu
        const existingMenu = document.querySelector('.image-context-menu');
        if (existingMenu) {
            existingMenu.parentElement.remove();
        }

        const menuContainer = document.createElement('div');
        //const { adjustedX, adjustedY } = this.adjustMenuPosition(e.pageX, e.pageY);
        const { subMenuPosition, menuPosition } = this.adjustMenuPosition(e.pageX);

        // Create and render context menu
        ReactDOM.render(
            <ImageContextMenu
                x={menuPosition}
                y={e.pageY}
                onClose={() => {
                    if (document.body.contains(menuContainer)) {
                        document.body.removeChild(menuContainer);
                    }
                }}
                onAction={(action) => {
                    this.handleImageAction(action);
                    if (document.body.contains(menuContainer)) {
                        document.body.removeChild(menuContainer);
                    }
                }}
                subMenuPosition={subMenuPosition}
            />,
            menuContainer
        );

        document.body.appendChild(menuContainer);

        // Close menu when clicking outside
        const closeMenu = (e) => {
            if (!menuContainer.contains(e.target)) {
                if (document.body.contains(menuContainer)) {
                    document.body.removeChild(menuContainer);
                }
                document.removeEventListener('click', closeMenu);
            }
        };

        setTimeout(() => {
            document.addEventListener('click', closeMenu);
        }, 0);
    }

    adjustMenuPosition(x) {
        const menuWidth = 220; // Approximate menu width
        const subMenuWidth = menuWidth + 200

        const leftSpace = window.innerWidth - x;
        const menuPosition = leftSpace > menuWidth ? x : x - 210
        const subMenuPosition = leftSpace > subMenuWidth ? {left : '100%'} : {right: '100%'}

        return { subMenuPosition, menuPosition};
    }

    handleImageAction(action) {
        const node = this.domNode;
        let img

        const quillContainer = this.parent.parent.domNode.parentNode.children;
        const expectedTargetHTML = `<div style=\"position: absolute; height: 12px; width: 12px; background-color: white; border: 1px solid rgb(119, 119, 119); box-sizing: border-box; opacity: 0.8; cursor: nwse-resize; left: -6px; top: -6px;\"></div>`
        const imageResizeFrameDiv = Array.from(quillContainer).find(child => child.children.length === 5 && child.children[0].outerHTML === expectedTargetHTML)
        if (imageResizeFrameDiv) {
            // If resize frame exists, find the image it's associated with
            // The resize frame's position corresponds to the selected image
            const frameLeft = parseInt(imageResizeFrameDiv.style.left);
            const frameTop = parseInt(imageResizeFrameDiv.style.top);
            const allImages = Array.from(this.parent.parent.domNode.parentNode.querySelectorAll('img'));

            img = allImages
                .find(imgEl =>
                    imgEl &&
                    Math.abs(imgEl.offsetLeft - frameLeft) <= 1 &&
                    Math.abs(imgEl.offsetTop - frameTop) <= 1
                );
        } else {
            img = node.querySelector('img')
        }

        // Remove existing alignment classes
        const alignmentClasses = [
            'align-left', 'align-right', 'align-center',
            'align-left-with-text', 'align-right-with-text', 'align-center-with-text'
        ];

        if (img){
            img.classList.remove(...alignmentClasses);
            const parent = img.parentNode.parentNode.parentNode // img -> span -> custom-image -> p

            // Handle different actions
            const newParagraph = document.createElement('p');
            const textNodes = Array.from(parent.childNodes).filter(node => node.localName !== 'custom-image');
            const textNodesHtml = textNodes.map(node => {
                return node.nodeType === Node.TEXT_NODE ? node.textContent : node.outerHTML})
                .join('')
            newParagraph.innerHTML = textNodesHtml
            switch (action) {
                case 'alignLeft':
                case 'alignRight':
                case 'alignCenter':
                    parent.removeAttribute('contenteditable');
                    img.classList.add(`align-${action.replace('align', '').toLowerCase()}`);
                    break;
                case 'alignLeftWithText':
                    parent.setAttribute('contenteditable', 'false'); // Prevent Quill from editing the table as a whole
                    // Remove all text nodes
                    textNodes.forEach(textNode => {
                        parent.removeChild(textNode);
                    });
                    parent.insertAdjacentElement('afterend', newParagraph);
                    img.classList.add('align-left-with-text');
                    break;
                case 'alignRightWithText':
                    parent.setAttribute('contenteditable', 'false'); // Prevent Quill from editing the table as a whole
                    // Remove all text nodes
                    textNodes.forEach(textNode => {
                        parent.removeChild(textNode);
                    });
                    parent.insertAdjacentElement('afterend', newParagraph);
                    img.classList.add('align-right-with-text');
                    break;
                case 'alignCenterWithText' :
                    parent.removeAttribute('contenteditable');
                    break
                case 'removeImage':
                    img.parentNode.parentNode.remove()
                    if (imageResizeFrameDiv){imageResizeFrameDiv.style.display = 'none';}
                    break;
            }
            const leftOffset = img.offsetLeft + 'px'
            const topOffset = img.offsetTop + 'px'
            if (imageResizeFrameDiv && leftOffset){
                imageResizeFrameDiv.style.left = leftOffset;
            }
            if (imageResizeFrameDiv && topOffset){
                imageResizeFrameDiv.style.top = topOffset;
            }
        }
    }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'custom-image';
Quill.register(ImageBlot)

Quill.register('modules/imageResize', ImageResize);

const BlockEmbed = Quill.import('blots/block/embed');
const Inline = Quill.import('blots/inline');
const Block = Quill.import('blots/block');

class TableBlot extends Embed {
    /*static create(value) {
        const node = super.create();

        // Create the image element
        const img = document.createElement('img');

        img.setAttribute('src', value.src || value);  // || value case is for the old images only, in that case tha value is a string containing the src directly
        //img.classList.add('ql-image'); // Helps the resize module recognize the image
        img.setAttribute('alt', value.alt || ''); // Support alt text

        const styleOfOldImages = 'max-width: 100%; height: auto; display: block;'
        if (value && !value.src) {
            img.setAttribute('style', styleOfOldImages); // Apply any styles that the old images had
        }

        if (value.width) {
            img.setAttribute('width', value.width); // Apply any styles if provided
        }
        if (value.height) {
            img.setAttribute('height', value.height); // Apply any styles if provided
        }
        if (value.classList && Array.isArray(value.classList)) {
            img.classList.add(...value.classList); // Restore the classList
        }

        //container.appendChild(img);
        node.appendChild(img);

        return node;
    }

    static value(node) {
        // Find the img element within the container
        const img = node.querySelector('img');
        if (!img) return null;

        return {
            src: img.getAttribute('src'),
            width: img.getAttribute('width'),
            height: img.getAttribute('height'),
            classList: Array.from(img.classList),
        };
    }

     */
    static create(value) {
        // Check if `value` includes HTML to reconstruct the saved table
        //const container = document.createElement('div')
        const container = super.create(); // Use the `table` tag directly as the blot
        if (value.html) {
            container.innerHTML = value.html; // Set the HTML
            return container
        }

        const { rows = 2, columns = 2 } = value || {};

        container.setAttribute('contenteditable', 'false');
        const table = document.createElement('table');

        table.setAttribute('class', 'custom-table');
        table.setAttribute('contenteditable', 'false'); // Prevent Quill from editing the table as a whole

        // Create <tbody>
        const tbody = document.createElement('tbody');
        for (let i = 0; i < rows; i++) {
            const tr = document.createElement('tr');
            for (let j = 0; j < columns; j++) {
                const td = document.createElement('td');
                td.contentEditable = 'false'; // Allow cell content editing
                const div = document.createElement('div');
                div.style.height = '100%'; // Make the div take up the full height of the td
                div.contentEditable = 'false'; // Allow cell content editing

                const p = document.createElement('p');
                p.style.height = '100%'; // Make the div take up the full height of the td
                p.contentEditable = 'true'; // Allow typing only inside the <p>
                p.innerHTML = '<br>'; // Ensure an empty but non-deletable paragraph
                div.appendChild(p);
                td.appendChild(div);
                tr.appendChild(td);
            }
            tbody.appendChild(tr);
        }
        table.appendChild(tbody);
        container.appendChild(table);

        return container;
    }

    static value(node) {
        // Serialize the table structure into an object with rows and columns
        const rows = node.querySelectorAll('tbody tr').length;
        const columns = node.querySelectorAll('tbody tr:first-child td').length;

        return {
            rows,
            columns,
            html: node.innerHTML // Include full HTML if needed
        };
    }
}

TableBlot.blotName = 'table'
TableBlot.tagName = 'div' // Wraps the table inside a div
Quill.register(TableBlot)

// Custom SVG for the table button
const tableIcon = `
<svg width="16" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <rect x="3" y="3" width="7" height="7"></rect>
    <rect x="14" y="3" width="7" height="7"></rect>
    <rect x="14" y="14" width="7" height="7"></rect>
    <rect x="3" y="14" width="7" height="7"></rect>
</svg>`;
// Add the SVG to the Quill toolbar icons
const icons = ReactQuill.Quill.import('ui/icons');
icons['table'] = tableIcon;

Quill.register('modules/customTableResize', CustomTableResize);
class Editor extends React.Component {
    constructor (props) {
        super(props)
        this.quillRef = React.createRef()
        this.state = {isTableSelectorOpen: false}
        this.handleChange = this.handleChange.bind(this)
        this.selectLocalImage = this.selectLocalImage.bind(this)
        this.saveToServer = this.saveToServer.bind(this)
        this.insertToEditor = this.insertToEditor.bind(this)

        this.handleInsertTable = this.handleInsertTable.bind(this)
        this.handleTableDialogClose = this.handleTableDialogClose.bind(this)
        this.handleTableSelect = this.handleTableSelect.bind(this)
    }

    /*
    componentDidMount() {
        const quill = this.quillRef?.current?.getEditor();

        if (quill) {
            quill.getModule('toolbar').addHandler('bold', () => {
                const range = quill.getSelection();
                if (!range) return;

                const [leafBlot] = quill.getLeaf(range.index);


                // Find the parent ImageBlot by traversing up
                //let currentNode = leafBlot && leafBlot.domNode.querySelector('.image-left-text, .image-right-text');
                let imageBlot = leafBlot.parent.prev || leafBlot;
                const nameOfSelectedElement = leafBlot.domNode?.nodeName
                // If we found an ImageBlot parent and have selected text
                if (false && nameOfSelectedElement !== '#text' && imageBlot instanceof ImageBlot) {
                    const selectedSpan = getSelectedSpanFromImageBlot(imageBlot)
                    // Apply bold to selected text if there's a selection
                    if (selectedSpan) {
                        imageBlot.applyStyleToSelection(selectedSpan, 'fontWeight',
                            // Toggle bold based on current selection style
                            selectedSpan.style.fontWeight === 'bold' ? 'normal' : 'bold'
                        );
                    }







                    if (imageBlot.currentTextSelection) {
                        imageBlot.applyStyleToSelection('fontWeight',
                            // Toggle bold based on current selection style
                            imageBlot.currentTextSelection.span.style.fontWeight === 'bold' ? 'normal' : 'bold'
                        );
                    }


                } else {
                    // Default bold behavior for regular text
                    quill.format('bold', !quill.getFormat(range).bold);
                }


            });
        }
    }

     */


    handleChange (html) {
        this.props.onChange(html)
    }

    selectLocalImage() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        // Listen upload local image and save to server
        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.saveToServer(file)
            } else {
                console.warn('You could only upload images.');
            }
        };
    }

    saveToServer(file) {
        const data = new FormData()

        const fileSize = file.size
        const chunkSize = 15 * 1024 * 1024

        let fileChunk = null, chunkNb = 0
        for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
            if (start + chunkSize > fileSize) {
                fileChunk = file.slice(start, fileSize)
            }
            else {
                fileChunk = file.slice(start, start + chunkSize)
            }
            data.append('imageChunks', fileChunk, file.name)
        }

        if(this.props.user) {
            data.append('user', JSON.stringify(this.props.user))
        }
        data.append('fileSize', fileSize)
        data.append('chunkNb', chunkNb)

        const options = {
            headers: { 'Content-Type': 'multipart/form-data' }
        }

        axios.post('/image', data, options)
            .then(res => {
                const {name, date} = res.data
                const fullDate = moment(date).format('YYYY-MM-DD_HH-mm-ss')
                const url = `/image/${fullDate}_${name}`
                this.insertToEditor(url)
            })
            .catch(() => {
                toastr.error(this.props.t('errorUploading'))
            })
    }

    insertToEditor(url) {
        console.log('Quill img:', this.quillRef?.current?.getEditor());

        const editor = this.quillRef.current.getEditor()
        const range = editor.getSelection()

        const hostname = window.location.hostname
        const link = hostname === 'localhost'
            ?  `http://${hostname}:9003${url}`
            :  `https://${hostname}${url}`

        editor.insertEmbed(range.index, 'image', {
            src: link,
            classList: ['alignCenterWithText'],
            //alignment: 'center-with-text', // Or dynamically set based on user input
            //style: 'max-width: 100%; height: auto; display: block;'
        });
    }

    handleInsertTable = () => {
        const editor = this.quillRef.current.getEditor();
        const range = editor.getSelection();

        // Save the current selection
        this.savedRange = range;

        this.setState({
            isTableSelectorOpen: true,
        });
    };

    handleTableDialogClose = () => {
        const editor = this.quillRef.current.getEditor();
        const range = editor.getSelection();
        this.setState({
            isTableSelectorOpen: false,
        });
    };

    handleTableSelect = (tableConfig) => {
        this.setState({
            isTableSelectorOpen: false,
        }, () => {
            const editor = this.quillRef.current.getEditor();
            const length = editor.getLength();
            // Restore the saved selection
            editor.setSelection(this.savedRange || length)
            const range = editor.getSelection();
            editor.insertEmbed(range.index, 'table', tableConfig);
        });
    };

    static formats = [
        //'header',
        'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        //'blockquote',
        'color', 'background', 'list', 'bullet', 'indent', 'align',
        'link',
        'image',
        'table'
        //, 'video'
    ]

    render() {
        const { value, touched, error, path, required, t, readOnly, disabled, placeholder } = this.props

        const modules = {
            toolbar: {
                container: [
                    [
                        //{ 'header': '1'}, {'header': '2'},
                        { 'font': Font.whitelist },
                    ],
                    [{size: []}],
                    //[],
                    [{align: []}, 'bold', 'italic', 'underline', 'strike',
                        //'blockquote'
                    ],
                    [{ 'color': [] }, { 'background': [] }],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                        {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image', 'table'],
                    ['clean'],
                ],
                handlers: {
                    image: this.selectLocalImage,
                    table: this.handleInsertTable
                }
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
            imageResize: {
                modules: ['Resize', 'DisplaySize'], // Excludes 'Align'
            },
            customTableResize: {}
        }

        if(readOnly) {
            return (
                <div className="ql-container ql-snow ql-disabled" style={{border: 'none'}}>
                    <div className="ql-editor" data-gramm="false" contentEditable="false">
                        <div dangerouslySetInnerHTML={{__html: value}} />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <label className="QuillTextEditor-label">
                    {t(path)} {required && "*"}
                </label>
                <div style={{padding: '5px'}}>
                    <ReactQuill
                        ref={this.quillRef}
                        theme={'snow'}
                        readOnly={disabled}
                        onChange={this.handleChange}
                        value={value || ""}
                        modules={modules}
                        formats={Editor.formats}
                        placeholder={placeholder}
                    />
                </div>
                {touched === 'true' && (error && <ValidationUI error={error} />)}
                {this.state.isTableSelectorOpen && (
                    <TableSelector
                        onSelect={this.handleTableSelect}
                        onClose={this.handleTableDialogClose}
                    />
                )}
            </div>
        )
    }
}

export default Editor
